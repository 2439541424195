@font-face {
  font-family: 'OpenSans Regular';
  src: url('OpenSans-Regular.ttf') format('opentype');
  font-weight: normal;
  font-display: auto;
}
@font-face {
  font-family: 'OpenSans Bold';
  src: url('OpenSans-Bold.ttf') format('opentype');
  font-weight: 700;
  font-display: auto;
}
@font-face {
  font-family: 'OpenSans Semi Bold';
  src: url('OpenSans-SemiBold.ttf') format('opentype');
  font-weight: 600;
  font-display: auto;
}
@font-face {
  font-family: 'OpenSans Extra Bold';
  src: url('OpenSans-ExtraBold.ttf') format('opentype');
  font-weight: 800;
  font-display: auto;
}
@font-face {
  font-family: 'OpenSans Italic';
  src: url('OpenSans-Italic.ttf') format('opentype');
  font-weight: normal;
  font-display: auto;
}
@font-face {
  font-family: 'OpenSans Light Italic';
  src: url('OpenSans-Italic.ttf') format('opentype');
  font-weight: 300;
  font-display: auto;
}
@font-face {
  font-family: 'OpenSans Bold Italic';
  src: url('OpenSans-BoldItalic.ttf') format('opentype');
  font-weight: 700;
  font-display: auto;
}
@font-face {
  font-family: 'OpenSans Semi Bold Italic';
  src: url('OpenSans-SemiBoldItalic.ttf') format('opentype');
  font-weight: 600;
  font-display: auto;
}
@font-face {
  font-family: 'OpenSans Light';
  src: url('OpenSans-Light.ttf') format('opentype');
  font-weight: 400;
  font-display: auto;
}
@font-face {
  font-family: 'OpenSans Extra Bold Italic';
  src: url('OpenSans-ExtraBoldItalic.ttf') format('opentype');
  font-weight: 800;
  font-display: auto;
}
@font-face {
  font-family: 'Helvetica Neue Regular';
  src: url('HelveticaNeue-Regular.ttf') format('opentype');
  font-weight: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Helvetica Neue Bold';
  src: url('HelveticaNeue-Bold.ttf') format('opentype');
  font-weight: 600;
  font-display: auto;
}

/* ARCADIA */
@font-face {
  font-family: 'Inter Regular';
  src: url('Inter-Regular.ttf') format('opentype');
  font-weight: normal;
  font-display: auto;
}
@font-face {
  font-family: 'Inter Bold';
  src: url('Inter-Bold.ttf') format('opentype');
  font-weight: 700;
  font-display: auto;
}
@font-face {
  font-family: 'Inter Semi Bold';
  src: url('Inter-SemiBold.ttf') format('opentype');
  font-weight: 600;
  font-display: auto;
}

@font-face {
  font-family: 'Inter Medium';
  src: url('Inter-Medium.ttf') format('opentype');
  font-weight: 500;
  font-display: auto;
}

@font-face {
  font-family: 'Inter Italic';
  src: url('Inter-Italic.ttf') format('opentype');
  font-weight: normal;
  font-display: auto;
}

@font-face {
  font-family: 'Inter Bold Italic';
  src: url('Inter-BoldItalic.ttf') format('opentype');
  font-weight: 700;
  font-display: auto;
}
@font-face {
  font-family: 'Inter Semi Bold Italic';
  src: url('Inter-SemiBoldItalic.ttf') format('opentype');
  font-weight: 600;
  font-display: auto;
}
@font-face {
  font-family: 'Inter Medium Italic';
  src: url('Inter-MediumItalic.ttf') format('opentype');
  font-weight: 500;
  font-display: auto;
}

.inter-font {
  font-family: 'Inter Regular';
}

.arcadia-bg {
  background-color: #f9faff;
}

.inter-font * {
  font-family: 'Inter Regular';
}

.open-font {
  font-family: 'OpenSans Regular';
}

.open-font * {
  font-family: 'OpenSans Regular';
}
